import 'bootstrap';

import $ from 'jquery';
window.$ = window.jQuery = $;

import.meta.glob([
    '../img/**',
  ]);

 
// Jquery test
/*
if (window.jQuery) {
    // jQuery is loaded  
    alert("Yeah!");
} else {
    // jQuery is not loaded
    alert("Doesn't Work");
}*/